import { IDropdownOption } from '@fluentui/react'

export const amountFormat = /^[0-9]+(\.)?([0-9]{1,2})?$/
export const numberFormat = /^[0-9]+$/
export const alphanumericnameFormat = /^[a-zA-Z 0-9'/-]+$/
export const amountFormatWithNegative = /^-?[0-9]+(\.)?([0-9]{1,2})?$/
export const nameFormat = /^[A-Za-z]+$/
export const aumFeeType = 'AUMFEE'
export const flatFeeType = 'FLAT_FEE'
export const feeBillingType = 'Billing'
export const feeAccrualType = 'Accrual'
export const successMsg = 1
export const validationMsg = 2

export const BalanceTypeOptions: IDropdownOption[] = [
  {
    key: 'P',
    text: 'Principal'
  },
  {
    key: 'PI',
    text: 'Principal and Income'
  }
]

export const AssetValuationTypeOptions: IDropdownOption[] = [
  {
    key: 'FD',
    text: 'First Day'
  },
  {
    key: 'LD',
    text: 'Last Day'
  },
  {
    key: 'AD',
    text: 'Average Daily'
  }
]

export const PlatformBillingStatus: IDropdownOption[] = [
  {
    key: 'PEND',
    text: 'Pending'
  },
  {
    key: 'READY',
    text: 'Ready'
  },
  {
    key: 'INPGRS',
    text: 'In progress'
  },
  {
    key: 'GNRTD',
    text: 'Fee Generated'
  },
  {
    key: 'ADVAPRVD',
    text: 'Advisor Approved'
  },
  {
    key: 'COLLAPRVD',
    text: 'Collection Approved'
  },
  {
    key: 'FEECOLLSENT',
    text: 'Fee collection sent'
  },
  {
    key: 'COMPLETE',
    text: 'Complete'
  }
]
