import axios, { AxiosResponse } from 'axios'
import { format } from 'date-fns'
import { IApiOptions } from '../../../shared/contracts/IApiOptions'
import { IFlatFeeScheduleModel } from '../store/flatFeeScheduleTypes'
import {
  FeeDetailsItemsResponse,
  IFeeInvoices,
  IFeePlan,
  IGenerateInvoiceRequest,
  IInsertUpdateFeeBuildGroupRes,
  IInvoicesRequest,
  IPilotFeatureRequest,
  IScheduleInfo,
  ISendForCollectionRequest,
  IUpdateAccrualReq,
  IFeeListDetail,
  IFlagFeeSchedule,
  IGwesReportersRes,
  IFeeBilling,
  IPlatformFeeScheduleApiResp,
  IUpdatePlatformFeeScheduleReq,
  IPlatFormFeeDetailsApiReq,
  IPlatFormFeeDetailsApiResp,
  IPlatFormFeeCalcPeriodResp
} from '../store/types'
import {
  IUpdateFeeSchedule,
  IUpdateFeeScheduleResponseModel
} from '../store/updateFlatFeeScheduleTypes'

import {
  IDeleteResponse,
  IFeeCategory,
  IFeeCharge,
  IFeeFilter,
  IFeeScheduleFilter,
  IFeeType,
  IUpdateFeeAnnualAmountReq
} from './types/feefilter.type'
import {
  IAssetLookupResponse,
  IEditGroupResponse,
  // IEditOverrideResponse,
  // IFeeAssignment,
  // IEditAssignmentResponse,
  IEditOverrideResponse,
  IFeeAssignment,
  IFeeDeleteAssignment,
  // IFeeOverrideListItem,
  IGroup,
  IInsertUpdateFeeOverrideRes,
  // IInserUpdateFeeAssignmentReq,
  IInserUpdateFeeOverrideReq,
  // ITiredAumFeesForm,
  IFeeGroupListItem,
  IFeeOverrideListItem,
  IInsertUpdateFeeBuildGroupApiRes,
  IInserUpdateFeeGroupReq,
  IInsertUpdateTiredAumFeesApiRes,
  IAccountRootModel,
  ITrustAssignmentListingModel,
  ITrustActionModel
} from './types/types'
import { IUpdateAccountFeeModel } from './types/updateFeeType'

const getKeyValue =
  <U extends keyof T, T extends Record<string, any>>(key: U) =>
  (obj: T) =>
    obj[key]

const fetchFeeSchedulesService = (
  filter: IFeeFilter | null | undefined,
  options: IApiOptions
): Promise<AxiosResponse<IScheduleInfo[] | undefined>> => {
  const filterString: string = filter
    ? Object.keys(filter)
        .map(
          (key) =>
            `${key}=${getKeyValue<keyof IFeeFilter, IFeeFilter>(
              key as keyof IFeeFilter
            )(filter)}`
        )
        .join('&')
    : ''
  const url = `${options.apiRoot}/feeutil/feeSchedules?${filterString}`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchFeeGridSchedulesService = (
  filter: IFeeScheduleFilter,
  options: IApiOptions
): Promise<AxiosResponse<IScheduleInfo[] | undefined>> => {
  const url = `${options.apiRoot}/cdm/Fee/GetFeeStructure?status=${filter.status}&trusttype=${filter.trusttype}`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchFeeTypesService = (
  options: IApiOptions
): Promise<AxiosResponse<IFeeType[]>> => {
  const url = `${options.apiRoot}/feeutil/fees`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchFeeCategoriesService = (
  options: IApiOptions
): Promise<AxiosResponse<IFeeCategory[]>> => {
  const url = `${options.apiRoot}/feeutil/feeCategories`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchFeeService = (
  id: string,
  options: IApiOptions
): Promise<AxiosResponse<IScheduleInfo>> => {
  const url = `${options.apiRoot}/feeutil/feeSchedule/${id}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchFeePastChargesService = (
  id: string,
  options: IApiOptions
): Promise<AxiosResponse<IFeeCharge[]>> => {
  const url = `${options.apiRoot}/feeutil/feeSchedule/${id}/pastCharges`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchFeeFutureService = (
  id: string,
  options: IApiOptions
): Promise<AxiosResponse<IFeeCharge[]>> => {
  const url = `${options.apiRoot}/feeutil/feeSchedule/${id}/futureCharges`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const deleteScheduleFeeService = (
  id: string,
  options: IApiOptions
): Promise<AxiosResponse> => {
  const url = `${options.apiRoot}/feeutil/deleteFeeSchedule/${id}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post(url, null, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const deleteFeeScheduleFeeService = async (
  id: string,
  options: IApiOptions
): Promise<IDeleteResponse> => {
  const url = `${options.apiRoot}/cdm/Fee/InsertUpdateFeeStructureDetails`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  const data = { feestructureid: Number(id), actionCode: 'D' }
  const result = await axios
    .post(url, data, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
  return result
}

const activateFeeScheduleService = async (
  payload: IFlagFeeSchedule,
  options: IApiOptions
): Promise<AxiosResponse> => {
  const url = `${options.apiRoot}/feeutil/flagFeeSchedule`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  const data = {
    ActiveFlag: payload.ActiveFlag,
    FeeScheduleId: payload.FeeScheduleId
  }
  const result = await axios
    .post(url, data, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
  console.log(result)
  return result
}

const addScheduleFeeService = (
  fee: IScheduleInfo,
  options: IApiOptions
): Promise<AxiosResponse> => {
  const url = `${options.apiRoot}/feeutil/feeSchedule`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
    accept: 'application/json'
  }
  return axios
    .post(url, fee, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const chargeFeeService = (
  chargeDate: Date,
  options: IApiOptions
): Promise<AxiosResponse> => {
  const date = format(chargeDate, 'yyyy-MM-dd')
  const url = `${options.apiRoot}/feeutil/feeSchedule/${date}/nfsFile`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
    accept: 'application/json'
  }
  return axios
    .post(url, null, { headers, cancelToken: options.cancelToken })
    .then((res) => res.data)
}
const fetchDashboardFeesService = (monthyear: string, options: IApiOptions) => {
  const splitMonthYear = monthyear?.split('-')
  const yearString = splitMonthYear?.[1] || ''
  const monthString = splitMonthYear?.[0] || ''
  const url = `${options.apiRoot}/feeutil/accruals?Year=${yearString}&Month=${monthString}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IFeePlan[] | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const fetchPlatformFeeScheduleService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/cdm/Fee/GetPlatformFeeSchedule`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IPlatformFeeScheduleApiResp | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const updatePlatformFeeScheduleService = (
  req: IUpdatePlatformFeeScheduleReq,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/Fee/UpdatePlatformFeeSchedule`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post(url, req, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchPlatformFeeDetailService = (
  options: IApiOptions,
  payload?: IPlatFormFeeDetailsApiReq
) => {
  const url = `${options.apiRoot}/cdm/fee/GetPlatFormFeeCalc?billingQtrEndDate=${payload?.billingQtrEndDate}&accountNo=${payload?.accountNo}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IPlatFormFeeDetailsApiResp | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchPlatformFeeCalcPeriodService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/cdm/fee/GetPlatFormFeeCalcPeriod`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IPlatFormFeeCalcPeriodResp | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const fetchDashboardFeesBillingService = (
  monthyear: string,
  options: IApiOptions
) => {
  const splitMonthYear = monthyear?.split('-')
  const yearString = splitMonthYear?.[1] || ''
  const monthString = splitMonthYear?.[0] || ''
  const url = `${options.apiRoot}/cdm/Fee/GetClientBilling?Year=${yearString}&Month=${monthString}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IFeeBilling | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const runBillingService = (monthyear: string, options: IApiOptions) => {
  const splitMonthYear = monthyear?.split('-')
  const yearString = splitMonthYear?.[1] || ''
  const monthString = splitMonthYear?.[0] || ''
  const url = `${options.apiRoot}/cdm/Fee/UpdateRunBilling?Year=${yearString}&Month=${monthString}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const fetchAccrualsService = (
  monthyear: string,
  selectedFeePlan: IFeePlan,
  options: IApiOptions
) => {
  const splitMonthYear = monthyear?.split('-')
  const yearString = splitMonthYear?.[1] || ''
  const PartyUniqueId = selectedFeePlan?.PartyUniqueId || ''
  const feeCategoryCode = selectedFeePlan?.FeeCategoryCode || ''
  const feeCategoryUrlPart = feeCategoryCode
    ? `&FeeCategoryCode=${feeCategoryCode}`
    : ''
  const url = `${options.apiRoot}/feeutil/accruals?Year=${yearString}&PartyUniqueId=${PartyUniqueId}${feeCategoryUrlPart}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IFeePlan[] | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const getPilotFeature = (req: IPilotFeatureRequest, options: IApiOptions) => {
  const url = `${options.apiRoot}/trustapi/api/PilotFeature/Get`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios
    .post(url, req, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const updateAccrualsService = (
  accrualReq: IUpdateAccrualReq[],
  options: IApiOptions
): Promise<AxiosResponse> => {
  const url = `${options.apiRoot}/feeutil/accruals`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
    accept: 'application/json'
  }
  return axios
    .put(url, accrualReq, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const viewInvoiceService = (req: IFeeInvoices, options: IApiOptions) => {
  const url = `${options.apiRoot}/trustapi/api/Invoice/Download?invoiceid=${req?.invoiceid}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<string>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const generateInvoiceService = (
  req: IGenerateInvoiceRequest[],
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/invoice/Generate`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post(url, req, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const closeMonthService = (period: string, options: IApiOptions) => {
  const url = `${options.apiRoot}/trustapi/a/CloseMonth`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  const requestData = {
    period,
    isclosed: true
  }

  return axios
    .post<void>(url, requestData, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const fetchInvoicesService = (
  requestData: IInvoicesRequest[],
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/Invoice/Get`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios
    .post<IFeeInvoices[] | undefined>(url, requestData, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const downloadD365ExcelService = (
  monthyear: string,
  legalEntity: string | undefined,
  options: IApiOptions
) => {
  const splitMonthYear = monthyear?.split('-')
  const yearString = parseInt(splitMonthYear?.[1]) || null
  const monthString = parseInt(splitMonthYear?.[0]) || null
  const location = 'gl'
  const url = `${options.apiRoot}/feeutil/gl/accruals`
  const data = {
    Year: yearString,
    Month: monthString,
    location,
    LegalEntity: legalEntity
  }
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<string | undefined>(url, data, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const deleteFeeInvoiceService = (
  id: string,
  options: IApiOptions
): Promise<AxiosResponse> => {
  const url = `${options.apiRoot}/trustapi/api/Invoice/Delete?invoiceid=${id}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const sendForCollectionService = (
  req: ISendForCollectionRequest[],
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/feeutil/charges/MarkForCollection`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post(url, req, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const updateAnnualAmountService = (
  req: IUpdateFeeAnnualAmountReq,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/feeutil/feeSchedule/${req.id}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .put(
      url,
      {
        tiers: [
          {
            flatFee: req?.amount || '0'
          }
        ]
      },
      {
        headers,
        cancelToken: options.cancelToken
      }
    )
    .then((res) => res.data)
}

const fetchInvoiceDetailsItemsService = (id: string, options: IApiOptions) => {
  const url = `${options.apiRoot}/trustapi/api/Invoice/GetInvoiceDetails?invoiceid=${id}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<FeeDetailsItemsResponse>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchFeeScheduleService = (
  data: IFeeListDetail,
  options: IApiOptions
): Promise<AxiosResponse<IScheduleInfo>> => {
  const url = `${options.apiRoot}/cdm/Fee/GetFeeDetails?feestructureid=${data.feestructureid}&draftfeestructureid=${data.draftfeestructureid}&status=${data.isType}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const insertUpdateFeeOverrideService = (
  req: IInserUpdateFeeOverrideReq,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/Fee/InsertUpdateFeeOverrideDetails`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IInsertUpdateFeeOverrideRes>(url, req, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const insertUpdateFeeGroupService = (
  req: IInserUpdateFeeGroupReq,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/Fee/InsertUpdateGroupDetails`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IEditGroupResponse>(url, req, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchAssetClassSecurityService = (
  searchKey: string,
  options: IApiOptions
) => {
  const url = `${
    options.apiRoot
  }/cdm/Fee/GetFeeSecurityAssetClass?lookupvalue=${searchKey || 'assetclass'}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IAssetLookupResponse>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchExistingOverridebyIdService = (
  data: {
    id?: string
    draftid?: string
    status?: string
  },
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/Fee/GetFeeOverrideDetails?id=${data.id}&draftid=${data.draftid}&status=${data.status}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IEditOverrideResponse>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchFeeOverridesListService = (
  filter: IFeeScheduleFilter,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/Fee/GetFeeOverrideSummary?status=${filter.status}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IFeeOverrideListItem[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchGroupByAccountIdService = (id: string, options: IApiOptions) => {
  const url = `${options.apiRoot}/cdm/Fee/GetGroupName?accountnumber=${id}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IGroup[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const insertUpdateTiredAumFeesService = (
  req: IUpdateAccountFeeModel,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/Fee/InsertUpdateAccountAssignmentDetails`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IInsertUpdateTiredAumFeesApiRes[]>(url, req, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchFeeAssignmentService = (status: string, options: IApiOptions) => {
  const url = `${options.apiRoot}/cdm/fee/GetAccountAssignmentSummary?status=${status}`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IFeeAssignment[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchTrustCategoryAssignmentService = (
  partyId: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/fee/GetClientAndTrustType?partyId=${
    partyId.length > 0 ? partyId : null
  }`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios
    .get<ITrustAssignmentListingModel[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const handleTrustCategoryActionService = (
  payload: ITrustActionModel,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/fee/InsertUpdateClientTrustType`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios
    .post(url, payload, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const deleteFeeAssignmentService = (
  req: IFeeDeleteAssignment,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/Fee/DeleteAccountAssignmentDetails`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  const data = {
    accountAssignmentid: req.item.accountAssignmentid,
    actioncode: 'D',
    isdraft: req.item.isdraft
  }
  return axios
    .post(url, data, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchFeeGroupListService = (status: string, options: IApiOptions) => {
  const url = `${options.apiRoot}/cdm/Fee/GetGroupSummary?status=${status}`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IFeeGroupListItem[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const insertUpdateFeeBuildGroupService = (
  req: IInsertUpdateFeeBuildGroupRes,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/Fee/InsertUpdateGroupDetails`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IInsertUpdateFeeBuildGroupApiRes>(url, req, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchExistingFeeBuildGroupService = (
  req: { id: string; status: string; draftId: string },
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/Fee/GetGroupDetail?groupsummaryid=${req?.id}&status=${req?.status}&groupdraftid=${req?.draftId}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IInsertUpdateFeeBuildGroupRes>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchExistingTiredAumFeesService = (
  req: { id: string; status: string; partyid: string | undefined },
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/Fee/GetAccountAssignmentDetail?accountassignmentid=${req?.id}&status=${req?.status}&partyid=${req?.partyid}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IAccountRootModel>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchAccountService = (
  req: { legalentityid: string },
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/Fee/GetSearchAPIAccounts?legalentityid=${req.legalentityid}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<any>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res?.data && res?.data?.accounts)
}

const fetchFlatFeeScheduleService = (
  options: IApiOptions,
  partyUniqueId: string
) => {
  const url = `${options.apiRoot}/feeutil/BillingAndAccruals?PartyUniqueId=${partyUniqueId}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IFlatFeeScheduleModel[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res?.data)
}

const updateFlatFeeScheduleService = async (
  payload: IUpdateFeeSchedule[],
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/feeutil/updateBillingAndaccruals`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  const results = await Promise.all(
    payload.map((item) => {
      return axios
        .put<IUpdateFeeScheduleResponseModel>(url, item, {
          headers,
          cancelToken: options.cancelToken
        })
        .then((res) => res.data)
    })
  )
  return results
}

const fetchAccountsForScheduleService = (
  req: { feestrutureid: number },
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/Fee/GetAccountsForSchedule?feestrutureid=${req.feestrutureid}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<string[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const getGwesReportService = (monthyear: string, options: IApiOptions) => {
  const splitMonthYear = monthyear?.split('-')
  const yearString = splitMonthYear?.[1] || ''
  const monthString = splitMonthYear?.[0] || ''

  const url = `${options.apiRoot}/cdm/fee/GetGWESReport?year=${yearString}&month=${monthString}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IGwesReportersRes | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export {
  updateFlatFeeScheduleService,
  fetchFlatFeeScheduleService,
  fetchFeeSchedulesService,
  fetchFeeGridSchedulesService,
  fetchFeeTypesService,
  fetchFeeCategoriesService,
  fetchFeeService,
  fetchFeePastChargesService,
  fetchFeeFutureService,
  deleteScheduleFeeService,
  deleteFeeScheduleFeeService,
  activateFeeScheduleService,
  addScheduleFeeService,
  chargeFeeService,
  fetchDashboardFeesService,
  fetchPlatformFeeScheduleService,
  updatePlatformFeeScheduleService,
  fetchPlatformFeeDetailService,
  fetchPlatformFeeCalcPeriodService,
  fetchDashboardFeesBillingService,
  runBillingService,
  fetchAccrualsService,
  getPilotFeature,
  updateAccrualsService,
  viewInvoiceService,
  generateInvoiceService,
  closeMonthService,
  fetchInvoicesService,
  downloadD365ExcelService,
  deleteFeeInvoiceService,
  sendForCollectionService,
  updateAnnualAmountService,
  fetchInvoiceDetailsItemsService,
  fetchFeeScheduleService,
  insertUpdateFeeOverrideService,
  fetchAssetClassSecurityService,
  fetchExistingOverridebyIdService,
  fetchFeeOverridesListService,
  fetchGroupByAccountIdService,
  insertUpdateTiredAumFeesService,
  fetchFeeAssignmentService,
  fetchFeeGroupListService,
  insertUpdateFeeGroupService,
  insertUpdateFeeBuildGroupService,
  fetchExistingFeeBuildGroupService,
  deleteFeeAssignmentService,
  fetchExistingTiredAumFeesService,
  fetchAccountService,
  fetchTrustCategoryAssignmentService,
  handleTrustCategoryActionService,
  fetchAccountsForScheduleService,
  getGwesReportService
}
