import {
  CheckboxVisibility,
  ConstrainMode,
  DetailsHeader,
  DetailsHeaderBase,
  DetailsListLayoutMode,
  IColumn,
  IColumnDragDropDetails,
  IColumnReorderOptions,
  IDetailsHeaderProps,
  IDetailsListProps,
  IDetailsRowProps,
  IDragDropEvents,
  IFocusZoneProps,
  ISelection,
  IShimmeredDetailsListStyles,
  IStyle,
  ITheme,
  ITooltipHostProps,
  mergeStyleSets,
  MessageBar,
  SelectionMode,
  ShimmeredDetailsList,
  TooltipHost
} from '@fluentui/react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getTheme } from '../../store/ui/selectors'

const alignRightClassName = 'align-right'
const cellPadding = '7px 10px'
const headerCellTitlePadding = '10px 0'
const getClassNames = (theme: ITheme) => {
  return mergeStyleSets({
    dataTable: {
      displayName: 'data-table',
      selectors: {
        '& .ms-DetailsHeader > div[class*="dropHintStyle-"]': {
          position: 'relative'
        },
        '& .ms-DetailsHeader > div[class*="borderWhileDragging-"], & .ms-DetailsHeader > div[class*="borderAfterDropping-"]':
          {
            outlineOffset: '-1px'
          },
        '& .ms-Button.ms-Button--icon': {
          width: '20px',
          height: '20px'
        },
        '& .ms-Icon': {
          fontSize: '1rem'
        },
        '& .ms-Button.ms-Button--icon:hover': {
          backgroundColor: theme.palette.neutralQuaternaryAlt
        },
        '& .ms-Button.ms-Button--icon.is-expanded': {
          backgroundColor: theme.palette.neutralQuaternaryAlt
        },
        '& .ms-DetailsRow-fields': {
          color: theme.palette.black
        },
        '& .ms-DetailsRow-cell': {
          padding: cellPadding,
          fontSize: `${theme.fonts.medium}px`,
          display: 'flex',
          alignItems: 'center',
          minHeight: '25px'
        },
        '& .ms-DetailsRow': {
          borderBottom: `solid 1px ${theme.palette.neutralLighter}`
        },
        '& .ms-DetailsRow-cellCheck, & .ms-DetailsHeader-cellIsCheck': {
          width: '50px'
        },
        '& .ms-DetailsRow-check': {
          height: 'auto'
        },
        '& .ms-DetailsHeader': {
          height: 'auto',
          lineHeight: 'normal',
          display: 'inline-flex',
          paddingTop: 0
        },
        '& .ms-DetailsHeader-dropHintCaretStyle': {
          top: 0
        },
        '& .ms-DetailsHeader-dropHintLineStyle': {
          height: 'auto'
        },
        '& .ms-List-cell:nth-child(odd) .ms-DetailsRow-cell': {
          backgroundColor: theme.palette.neutralLighterAlt
        },
        '& .ms-List-cell:nth-child(even) .ms-DetailsRow-cell, & .ms-DetailsHeader-cell':
          {
            backgroundColor: theme.palette.white
          },
        '& .ms-List-cell .ms-DetailsRow:hover .ms-DetailsRow-cell, & .ms-DetailsHeader-cell:hover':
          {
            backgroundColor: theme.palette.neutralLighter
          },
        '& .ms-DetailsRow.is-selected .ms-DetailsRow-cell, & .ms-List-cell .ms-DetailsRow.is-selected:hover .ms-DetailsRow-cell':
          {
            backgroundColor: theme.palette.neutralTertiaryAlt
          },
        [`& .${alignRightClassName}.ms-DetailsRow-cell`]: {
          textAlign: 'right',
          justifyContent: 'flex-end'
        },
        '& .ms-DetailsHeader-cellName': {
          whiteSpace: 'normal'
        },
        '& .ms-DetailsHeader-cell': {
          display: 'inline-flex',
          height: 'auto'
        },
        '& .ms-DetailsHeader-cellTitle': {
          display: 'flex',
          flexGrow: 1,
          padding: headerCellTitlePadding,
          cursor: 'pointer'
        },
        '& .ms-DetailsHeader-cell:not([aria-sort="none"]) .ms-DetailsHeader-cellTitle':
          {
            paddingLeft: 0
          },
        [`& .${alignRightClassName}.ms-DetailsHeader-cell`]: {
          textAlign: 'right',
          justifyContent: 'flex-end'
        },
        [`& .${alignRightClassName} .ms-DetailsHeader-cellTitle`]: {
          textAlign: 'right',
          justifyContent: 'flex-end'
        },
        [`& .${alignRightClassName} .ms-DetailsHeader-cellTitle i`]: {
          order: -1
        },
        '& .ms-DetailsHeader-cell > i': {
          alignSelf: 'center'
        }
      }
    },
    stickyLeft: {
      zIndex: 2,
      selectors: {
        '&.ms-DetailsRow-cell': {
          position: 'sticky'
        },
        '&.ms-DetailsHeader-cell, & + .ms-DetailsHeader-cellSizer': {
          position: 'sticky'
        },
        '& + .ms-DetailsHeader-cellSizer': {
          zIndex: 3
        }
      }
    },
    stickyLeftBorder: {
      borderRight: `solid 2px ${theme.palette.neutralLight}`
    }
  })
}

const getStickyLeftClass = (left: number, width: number) => {
  return mergeStyleSets({
    stickyLeftPosition: {
      left: `${left}px`,
      selectors: {
        '& + .ms-DetailsHeader-cellSizer': {
          left: `${left + width + 19 / 2}px`
        }
      }
    }
  })
}

export interface IDataTableProps {
  items?: any[]
  columns: IColumn[]
  isHeaderVisible?: boolean
  stickyHeaderOffset?: number
  stickyColumnOffset?: number
  layoutMode?: DetailsListLayoutMode
  constrainMode?: ConstrainMode
  checkboxVisibility?: CheckboxVisibility
  focusZoneProps?: IFocusZoneProps
  enableShimmer?: boolean
  shimmerLines?: number
  numStickyColumns?: number
  selection?: ISelection
  selectionMode?: SelectionMode
  selectionPreservedOnEmptyClick?: boolean
  columnReorderOptions?: IColumnReorderOptions
  dragDropEvents?: IDragDropEvents
  message?: string
  onColumnResize?: (
    column?: IColumn,
    newWidth?: number,
    columnIndex?: number
  ) => void
  onRenderRow?: IDetailsListProps['onRenderRow']
  onRenderDetailsFooter?: IDetailsListProps['onRenderDetailsFooter']
  onRenderItemColumn?: IDetailsListProps['onRenderItemColumn']
  onColumnHeaderClick?: IDetailsListProps['onColumnHeaderClick']
  onShouldVirtualize?: IDetailsListProps['onShouldVirtualize']
  getCellValueKey?: IDetailsListProps['getCellValueKey']
}

const createStickyColumns = (
  columns: IColumn[],
  numStickyColumns: number,
  stickyColumnOffset?: number,
  cssClasses?: ReturnType<typeof getClassNames>
) => {
  const stickyColumns = columns.map((column) => ({
    ...column,
    calculatedWidth: column.calculatedWidth || column.maxWidth,
    currentWidth: column.currentWidth || column.maxWidth
  }))

  const adjustStickyPosition = (column: IColumn, index: number) => {
    const left =
      index === 0
        ? 0
        : stickyColumns
            .slice(0, index)
            .reduce((a, x) => a + (x.calculatedWidth || 0) + 20, 0)

    const lastStickyColumnIndex = numStickyColumns - 1

    if (index > lastStickyColumnIndex) {
      return
    }

    const isLastStickyColumn = index === lastStickyColumnIndex

    const { stickyLeftPosition } = getStickyLeftClass(
      left + (stickyColumnOffset || 0),
      column.calculatedWidth || 0
    )
    const { stickyLeft, stickyLeftBorder } = cssClasses || {}

    const stickyClasses = [
      stickyLeft,
      stickyLeftPosition,
      isLastStickyColumn && stickyLeftBorder
    ]

    column.className = [column.className, ...stickyClasses]
      .filter((x) => x)
      .join(' ')

    column.headerClassName = [column.headerClassName, ...stickyClasses]
      .filter((x) => x)
      .join(' ')
  }

  if (numStickyColumns > 0) {
    stickyColumns.forEach(adjustStickyPosition)
  }

  return stickyColumns
}

export const DataTable: React.FC<IDataTableProps> = ({
  items,
  columns,
  isHeaderVisible,
  stickyHeaderOffset,
  stickyColumnOffset,
  layoutMode = DetailsListLayoutMode.justified,
  checkboxVisibility,
  focusZoneProps,
  enableShimmer = false,
  shimmerLines = 50,
  numStickyColumns = 0,
  constrainMode = ConstrainMode.unconstrained,
  selection,
  selectionMode = SelectionMode.none,
  selectionPreservedOnEmptyClick = false,
  onColumnResize,
  columnReorderOptions,
  dragDropEvents,
  message,
  onRenderRow,
  onRenderDetailsFooter,
  onRenderItemColumn,
  onColumnHeaderClick,
  onShouldVirtualize,
  getCellValueKey
}) => {
  const theme = useSelector(getTheme)
  const headerRef = useRef<DetailsHeaderBase>(null)

  const fixHeaderDragDropIndexInfo = useCallback(() => {
    if (!headerRef.current) {
      return
    }

    const anyHeaderRef = headerRef.current as any
    anyHeaderRef._onDropIndexInfo = anyHeaderRef._onDropIndexInfo && {
      sourceIndex: -1,
      targetIndex: -1
    }
  }, [])

  const renderDetailsHeader = useCallback(
    (detailsHeaderProps?: IDetailsHeaderProps) => (
      <DetailsHeader
        ref={headerRef}
        {...(detailsHeaderProps || ({} as any))}
        onColumnResized={(column, newWidth, columnIndex) => {
          column.minWidth = Math.max(newWidth, 50)
          fixHeaderDragDropIndexInfo()
          detailsHeaderProps?.onColumnResized?.(column, newWidth, columnIndex)
        }}
        onRenderColumnHeaderTooltip={(props) => {
          if (!props) {
            return null
          }

          return renderCustomHeaderTooltip({
            ...props,
            content: props?.column?.data?.tooltipContent
          })
        }}
      />
    ),
    [fixHeaderDragDropIndexInfo]
  )

  const headerWrapperStyles: IStyle = useMemo(
    () =>
      stickyHeaderOffset != null
        ? {
            position: 'sticky',
            zIndex: 3,
            top: stickyHeaderOffset + 'px'
          }
        : {},
    [stickyHeaderOffset]
  )

  const themedClasses = useMemo(() => getClassNames(theme), [theme])

  const containerRef = useRef<HTMLDivElement>(null)
  const [offsetLeft, setOffsetLeft] = useState(0)
  useEffect(() => {
    const offset = containerRef.current?.getBoundingClientRect()
    setOffsetLeft(offset?.left || 0)
  }, [])

  const stickyColumns = useMemo(() => {
    return createStickyColumns(
      columns,
      numStickyColumns || 0,
      stickyColumnOffset ?? offsetLeft,
      themedClasses
    )
  }, [columns, numStickyColumns, stickyColumnOffset, offsetLeft, themedClasses])

  const listItems = useMemo(
    () =>
      enableShimmer ? [] : items == null ? [] : items.length ? items : [null],
    [enableShimmer, items]
  )

  const onRenderCustomPlaceholder = useCallback(
    (
      rowProps: IDetailsRowProps,
      index?: number,
      defaultRender?: (props: IDetailsRowProps) => React.ReactNode
    ): React.ReactNode => {
      return enableShimmer ? (
        defaultRender?.(rowProps)
      ) : (
        <MessageBar>{message ?? 'No data available'}</MessageBar>
      )
    },
    [enableShimmer, message]
  )

  const fixedColumnReorderOptions: IColumnReorderOptions | undefined =
    columnReorderOptions && {
      ...columnReorderOptions,
      onColumnDrop: (dragDropDetails: IColumnDragDropDetails) => {
        columnReorderOptions?.onColumnDrop?.(dragDropDetails)
        fixHeaderDragDropIndexInfo()
      }
    }

  return (
    <div ref={containerRef}>
      <ShimmeredDetailsList
        styles={listStyles}
        constrainMode={constrainMode}
        className={themedClasses?.dataTable}
        compact={true}
        items={listItems}
        columns={stickyColumns}
        checkboxVisibility={checkboxVisibility}
        focusZoneProps={focusZoneProps}
        selectionMode={selectionMode}
        enableShimmer={enableShimmer}
        shimmerLines={shimmerLines}
        useReducedRowRenderer={true}
        onRenderCustomPlaceholder={onRenderCustomPlaceholder}
        columnReorderOptions={fixedColumnReorderOptions}
        dragDropEvents={dragDropEvents}
        layoutMode={layoutMode}
        isHeaderVisible={isHeaderVisible === false ? false : true}
        onRenderDetailsHeader={renderDetailsHeader}
        detailsListStyles={{ headerWrapper: headerWrapperStyles }}
        selection={selection}
        selectionPreservedOnEmptyClick={selectionPreservedOnEmptyClick}
        onColumnResize={onColumnResize}
        onRenderRow={onRenderRow}
        onRenderDetailsFooter={onRenderDetailsFooter}
        onColumnHeaderClick={onColumnHeaderClick}
        onRenderItemColumn={onRenderItemColumn}
        onShouldVirtualize={onShouldVirtualize}
        getCellValueKey={getCellValueKey}
      />
    </div>
  )
}

const listStyles: IShimmeredDetailsListStyles = {
  root: {
    position: 'relative',
    selectors: { ':after': { backgroundImage: 'none' } }
  }
}

const renderCustomHeaderTooltip = (tooltipHostProps: ITooltipHostProps) => {
  const { content } = tooltipHostProps

  return content ? (
    <TooltipHost
      content={content}
      tooltipProps={{
        maxWidth: '200px',
        styles: { root: { textAlign: 'center' } }
      }}
    >
      <span css={{ textDecoration: 'underline' }}>
        {tooltipHostProps.children}
      </span>
    </TooltipHost>
  ) : (
    tooltipHostProps.children
  )
}
