import { AppState } from '../../../store'

export const getFeeState = (state: AppState) =>
  state.modules.fees.updateFeeState
export const getIsFeeListLoaded = (state: AppState) =>
  state.modules.fees.isFeeListLoaded
export const getFeeListData = (state: AppState) => {
  let scheduledFees = state.modules.fees.scheduledFees
  scheduledFees = scheduledFees.map((item) => {
    if (item?.isActive) {
      item.InActiveDate = ''
    }
    return item
  })
  return scheduledFees
}

export const getFeeGridListData = (state: AppState) => state.modules.fees.fee
export const getFeeTypes = (state: AppState) => state.modules.fees.feeTypes
export const getFeeCategories = (state: AppState) =>
  state.modules.fees.feeCategories
export const getClientContext = (state: AppState) => state.context.client?.items
export const getSelectedFeeId = (state: AppState) =>
  state.modules.fees.selectedFeeId
export const getFeeId = (state: AppState) => state.modules.fees.feestructureid
export const getfeeDetails = (state: AppState) => state.modules.fees.feeDetails
export const getPastCharges = (state: AppState) =>
  state.modules.fees.pastCharges
export const getFutureCharges = (state: AppState) =>
  state.modules.fees.futureCharges
export const getFeeFormError = (state: AppState) =>
  state.modules.fees.feeFrom?.error
export const getAccountContext = (state: AppState) =>
  state.context.account?.items
export const getSelectedAcctIds = (state: AppState) =>
  state.context.account?.selectedItemIds
export const getChargeFeeState = (state: AppState) =>
  state.modules.fees.chargeFees
export const getScheduleFeeError = (state: AppState) => state.modules.fees.error
export const getDeleteFeeError = (state: AppState) =>
  state.modules.fees.deleteFeeError
export const getSelectedFeePlan = (state: AppState) =>
  state.modules.fees.selectedFeePlan
export const getSelectedMonthYear = (state: AppState) =>
  state.modules.fees.selectedMonthYear
export const getFeeBillingSelectedMonthYear = (state: AppState) =>
  state.modules.fees.feeBillingSelectedMonthYear
export const getDashboardFeesState = (state: AppState) =>
  state.modules.fees.dashboardFees
export const getplatformFeeScheduleState = (state: AppState) =>
  state.modules.fees.platformFeeSchedule
export const getUpdateplatformFeeScheduleState = (state: AppState) =>
  state.modules.fees.updatePlatformFeeSchedule
export const getPlatformFeeDetailState = (state: AppState) =>
  state.modules.fees.platformFeeDetail
export const getPlatformFeeCalcPeriodState = (state: AppState) =>
  state.modules.fees.platformFeeCalcPeriod
export const getFeeBillingDashboardState = (state: AppState) =>
  state.modules.fees.feeBillingDashboard
export const getRunBillingState = (state: AppState) =>
  state.modules.fees.runBillingState
export const getAccruals = (state: AppState) => state.modules.fees.accruals
export const getpilotFeature = (state: AppState) =>
  state.modules.fees.pilotFeatureState?.pilotFeatureList?.pilotFeatures
export const getUpdateAccrualsState = (state: AppState) =>
  state.modules.fees.updateAccruals
export const getGenerateInvoicesState = (state: AppState) =>
  state.modules.fees.generateInvoice
export const getInvoicesState = (state: AppState) =>
  state.modules.fees.invoicesState?.invoices
export const getExportD365State = (state: AppState) =>
  state.modules.fees.downloadD365State
export const getsendForCollectionState = (state: AppState) =>
  state.modules.fees.sendForCollectionState
export const getUpdateAnnualAmountState = (state: AppState) =>
  state.modules.fees.updateAmountState
export const getFetchInvoiceDetailsItemsState = (state: AppState) =>
  state.modules.fees.fetchInvoiceDetailsItemsState
export const getFetchFeeCategoriesState = (state: AppState) =>
  state.modules.fees.feeCategoriesState
export const getFetchFeeAdvisorsState = (state: AppState) =>
  state.modules.fees.feeAdvisorsState
export const getFetchFeeBusinessSegmentTypesState = (state: AppState) =>
  state.modules.fees.feeBusinessSegmentTypeState
export const getFetchFeeFrequencysState = (state: AppState) =>
  state.modules.fees.feeFrequencyTypeState
export const getFetchAgencyFeeState = (state: AppState) =>
  state.modules.fees.feeAgencyTypeState
export const getFetchDepositAccountState = (state: AppState) =>
  state.modules.fees.feeDepositAccountState
export const getFetchFeeCollectionTypeState = (state: AppState) =>
  state.modules.fees.feeCollectionTypeState
export const getFetchFeeServiceTypeState = (state: AppState) =>
  state.modules.fees.feeServiceTypeState
export const getFetchInvoiceFeeTypeState = (state: AppState) =>
  state.modules.fees.feeInvoiceTypeState
export const getCreateNewFeeState = (state: AppState) =>
  state.modules.fees.createFeeState
export const getFeeGLString = (state: AppState) =>
  state.modules.fees.feeGLStringState
export const getSelectedFeeSchedule = (state: AppState) =>
  state.modules.fees.selectedFeeSchedule
export const getFeeScheduleDetails = (state: AppState) =>
  state.modules.fees.feeScheduleDetails
export const getAssetSecurityDataState = (state: AppState) =>
  state.modules.fees.fetchAssetSecurityDataState
export const insertUpdateOverridesState = (state: AppState) =>
  state.modules.fees.insertUpdateFeeOverrideState
export const getAssetSecurityDataByRowState = (state: AppState) =>
  state.modules.fees.fetchAssetSecurityDataByRowState
export const getSecuritiesState = (state: AppState) =>
  state.modules.fees.fetchSecuritiesState
export const getExistingOverrideByIdState = (state: AppState) =>
  state.modules.fees.fetchOverrideDetailsById
export const insertUpdateFeeScheduleState = (state: AppState) =>
  state.modules.fees.insertUpdateFeeScheduleState
export const getFetchFeeOverrideListState = (state: AppState) =>
  state.modules.fees.fetchFeeOverrideListState
export const deleteOverrideState = (state: AppState) =>
  state.modules.fees.deleteOverrideState
export const getFetchGroupByAccountIdState = (state: AppState) =>
  state.modules.fees.fetchGroupByAccountId
export const insertUpdateTiredAumFeesState = (state: AppState) =>
  state.modules.fees.insertUpdateTiredAumFeesState
export const deleteAssignmentState = (state: AppState) =>
  state.modules.fees.deleteAssignmentState
export const getFetchFeeAssignmentState = (state: AppState) =>
  state.modules.fees.fetchFeeAssignmentState
export const getFetchTrustCategoryAssignmentExistState = (state: AppState) =>
  state.modules.fees.fetchTrustCategoryAssignmentExistState
export const getFetchTrustCategoryAssignmentState = (state: AppState) =>
  state.modules.fees.fetchTrustCategoryAssignmentState
export const getTrustCategoryUpdateState = (state: AppState) =>
  state.modules.fees.fetchTrustCategoryUpdateState
export const fetchBuildGroupState = (state: AppState) =>
  state.modules.fees.fetchFeeOverrideListState
export const getFetchFeeGroupListState = (state: AppState) =>
  state.modules.fees.fetchFeeGroupListState
export const deleteGroupState = (state: AppState) =>
  state.modules.fees.deleteGroupState
export const getInsertUpdateFeeBuildGroupState = (state: AppState) =>
  state.modules.fees.insertUpdateFeeBuildGroupState
export const getExistingBuildGroupState = (state: AppState) =>
  state.modules.fees.fetchExistingFeeBuildGroupState
export const getExistingTiredAumFeesState = (state: AppState) =>
  state.modules.fees.fetchExistingTiredAumFeesState
export const getAccountsState = (state: AppState) => state.modules.fees.accounts
export const getFlatFeeScheduleState = (state: AppState) =>
  state.modules.fees.flatFeeSchedule
export const getUpdateFlatFeeScheduleState = (state: AppState) =>
  state.modules.fees.updateFlatFeeSchedule
export const getUpdateFlatFeeScheduleValidationState = (state: AppState) =>
  state.modules.fees.updateFlatFeeScheduleValidation
export const getGlAccount = (state: AppState) =>
  state.modules.fees.glAccountState
export const getAccountsForSchedule = (state: AppState) =>
  state.modules.fees.accountsForSchedule
